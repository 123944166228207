import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Api } from 'src/app/services/api/api.service';
import { ProductService } from 'src/app/services/product/product.service';
import { UserService } from 'src/app/services/user/user.service';
import _ from 'lodash';
import { Product } from 'src/app/models/Product';
import { Files } from 'package-types';
import { ModalController } from '@ionic/angular';
import { EditComboModalComponent } from '@components/edit-combo-modal/edit-combo-modal.component';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ConfirmationModalComponent } from '@components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-combos',
  templateUrl: './combos.component.html',
  styleUrls: ['./combos.component.css'],
})
export class CombosComponent {
  constructor(
    public userService: UserService,
    public i18n: TranslateService,
    public router: Router,
    public modalController: ModalController,
    private productService: ProductService,
    private notificationService: NotificationService,
  ) {}

  activeMerchant: string | undefined = undefined;
  combos = [];
  products: Product[];
  imagesLinks: Files.Images.IdDimensionsLinks;

  async ionViewWillEnter() {
    this.combos = [];
    this.products = [];
    this.imagesLinks = {};
    if (this.userService.haveMerchant) {
      this.activeMerchant = this.userService.userData.merchantId;
      await this.fetchCombos();
    }
  }

  async fetchCombos() {
    console.log(this.activeMerchant);
    const { comboOffers } = await Api.getCombos(this.activeMerchant);
    this.combos = comboOffers;
    if (this.combos.length === 0) return;

    const params = {
      merchantId: this.activeMerchant,
      merProductIds: _.flatten(
        this.combos.map((order) => order.products.map((product) => product.merProductId)),
      ),
    };
    const { products, imagesLinks } = await this.productService.getFilteredProducts(params);

    this.products = products;
    this.imagesLinks = imagesLinks;
  }

  async onMerchantChange(ev) {
    this.combos = [];
    this.products = [];
    this.imagesLinks = {};
    this.activeMerchant = ev.target.value;
    this.fetchCombos();
  }

  async openComboModal(comboToEdit = null) {
    const modal = await this.modalController.create({
      component: EditComboModalComponent,
      componentProps: {
        comboToEdit,
        products: comboToEdit ? this.products : [],
        images: comboToEdit ? this.imagesLinks : {},
        merchantId: this.userService.haveMerchant
          ? this.userService.userData.merchantId
          : this.activeMerchant,
      },
      cssClass: 'subsystem-modal',
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
      const { action, comboOffer, newProducts, newImages } = data;
      this.products = [...this.products, ...newProducts];
      this.imagesLinks = { ...this.imagesLinks, ...newImages };
      if (action === 'create') {
        this.combos.unshift(comboOffer);
      }
      if (action === 'edit') {
        this.combos.splice(
          this.combos.findIndex((c) => c._id === comboOffer._id),
          1,
          comboOffer,
        );
      }
    }
  }

  async removeCombo(id) {
    const { success } = await ConfirmationModalComponent.show(
      this.modalController,
      'Remove Combo?',
      'This action cannot be undone',
    );
    if (!success) return;

    await Api.removeCombo(id);
    this.combos = this.combos.filter((c) => c._id !== id);
    await this.notificationService.notify('Combo was removed');
  }
}
